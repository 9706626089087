import TranslateWrapper from "@components/TranslateWrapper"
import Content from "@modules/Registration/CreatorRegistration/Content"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"

const CreatorRegistration = ({ location }) => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) return null

  return (
    <TranslateWrapper>
      <Helmet>
        <title>Nova conta (criador) - Anonimatta</title>
      </Helmet>
      <Content location={location} />
    </TranslateWrapper>
  )
}

export default CreatorRegistration
