import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import { Avatar, Button, Checkbox, Col, Form, Input, Row, Upload } from "antd"
import cx from "classnames"
import React, { useState } from "react"
import { useIntl } from "react-intl"

import styles from "./style.module.less"

const { TextArea } = Input

const StepPhotoRegistration = (props) => {
  const {
    handleStepSelected,
    handlePreviewCover,
    handlePreviewPhoto,
    cover,
    coverEmpty,
    photo,
    photoEmpty,
    loading,
    validate,
    loadingButton,
    setLoadingButton,
  } = props
  const [checked, setChecked] = useState(false)
  const intl = useIntl()
  const placeholderNickname = intl.formatMessage({ id: "typeNickname" })

  const terms = process.env.AWS_TERMS
  const privacy = process.env.AWS_PRIVACY

  const validateCheckboxTerms = () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.reject(
          intl.formatMessage({ id: "validateTermsAndPrivacy" })
        )
      }

      return Promise.resolve()
    },
  })

  const validateEmail = () => ({
    async validator(rule, value) {
      if (value) {
        const unique = await validate({ email: value })

        if (!unique) {
          return Promise.reject(
            intl.formatMessage({ id: "validateEmailUnique" })
          )
        }
      }

      return Promise.resolve()
    },
  })

  const handleChangeState = (e) => {
    const value = e.target.checked
    setChecked(value)
  }

  return (
    <>
      <div className={cx("mb32", styles.start)}>
        <span className="f14 normal scorpion-main">
          {translate("editPersonal")}
        </span>
        <span
          className={
            !loading
              ? "f14 fw6 pointer kimberly underline ml4"
              : styles.noneLoading
          }
          onClick={() => handleStepSelected(0)}
        >
          {translate("back")}.
        </span>
      </div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col span={12} xs={24} md={12} className="mb24">
          <div className={cx("tc", styles.photoContainer)}>
            <span className="f20 fw5 mine-shaft">
              {translate("profilePhoto")}
            </span>
            <div>
              <span className="f14 normal scorpion-main">
                {translate("choosePhoto")}
              </span>
            </div>
            <div className="flex justify-center">
              {photo ? (
                <Avatar src={photo.data} className={styles.photoAvatar} />
              ) : (
                <div
                  className={cx("bg-kimberly mt12 mb12", styles.photoEmpty)}
                />
              )}
            </div>
            <div>
              <Upload
                accept="image/*"
                name="photo"
                showUploadList={false}
                onChange={handlePreviewPhoto}
                customRequest={({ onSuccess }) => {
                  setTimeout(() => {
                    onSuccess("ok")
                  }, 0)
                }}
              >
                <Button
                  type="primary"
                  className="upload-photo"
                  disabled={loading}
                >
                  {!photo ? translate("upload") : translate("changePhoto")}
                </Button>
              </Upload>
            </div>
            {photoEmpty && (
              <div className="mt8">
                <span className={cx("f14", styles.errorImages)}>
                  {translate("errorPhoto")}
                </span>
              </div>
            )}
          </div>
        </Col>
        <Col span={12} xs={24} md={12} className="mb24">
          <div className={cx("tc", styles.photoContainer)}>
            <span className="f20 fw5 mine-shaft">
              {translate("coverPhoto")}
            </span>
            <div>
              <span className="f14 normal scorpion-main">
                {translate("chooseCover")}
              </span>
            </div>
            <div className="flex justify-center">
              {cover ? (
                <Avatar src={cover.data} className={styles.coverAvatar} />
              ) : (
                <div
                  className={cx("bg-kimberly mt12 mb12", styles.coverEmpty)}
                />
              )}
            </div>
            <div>
              <Upload
                accept="image/*"
                name="cover"
                showUploadList={false}
                onChange={handlePreviewCover}
                customRequest={({ onSuccess }) => {
                  setTimeout(() => {
                    onSuccess("ok")
                  }, 0)
                }}
              >
                <Button
                  type="primary"
                  className="upload-photo"
                  disabled={loading}
                >
                  {!cover ? translate("upload") : translate("changeCover")}
                </Button>
              </Upload>
            </div>
            {coverEmpty && (
              <div className="mt8">
                <span className={cx("f14", styles.errorImages)}>
                  {translate("errorCover")}
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col span={24}>
          <div className={styles.labelNickname}>
            <label className={cx("scorpion-main", styles.customLabel)}>
              {translate("descriptionTitle")}
            </label>
            <label className={cx("i scorpion-main", styles.customLabelTwo)}>
              {translate("optional")}
            </label>
          </div>
          <Form.Item
            name="describe"
            rules={[{ max: 50, message: "Limite de 600 caracteres" }]}
          >
            <TextArea disabled={loading} maxLength={600} showCount autoSize />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col span={12} xs={24} md={12}>
          <div className={styles.labelNickname}>
            <label className={cx("scorpion-main", styles.customLabel)}>
              {translate("publicMail")}
            </label>
            <label className={cx("i scorpion-main", styles.customLabelTwo)}>
              {translate("optional")}
            </label>
          </div>
          <Form.Item
            tooltip
            name="publicEmail"
            rules={[
              {
                type: "email",
                message: intl.formatMessage({ id: "errorEmail" }),
              },
              validateEmail,
            ]}
            validateTrigger="onBlur"
          >
            <Input
              className="input-general"
              /*  placeholder={placeholderNickname} */
              suffix={<Icon name="CreatorMail" />}
              disabled={loading}
            />
          </Form.Item>
        </Col>
        <Col span={12} xs={24} md={12}>
          <div className={styles.labelNickname}>
            <label className={cx("scorpion-main", styles.customLabel)}>
              Instagram
            </label>
            <label className={cx("i scorpion-main", styles.customLabelTwo)}>
              {translate("optional")}
            </label>
          </div>
          <Form.Item tooltip name="instagram">
            <Input
              className="input-general"
              suffix={<Icon name="CreatorInstagram" />}
              disabled={loading}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col span={12} xs={24} md={12}>
          <div className={styles.labelNickname}>
            <label className={cx("scorpion-main", styles.customLabel)}>
              Twitter
            </label>
            <label className={cx("i scorpion-main", styles.customLabelTwo)}>
              {translate("optional")}
            </label>
          </div>
          <Form.Item tooltip name="twitter">
            <Input
              className="input-general"
              suffix={<Icon name="CreatorTwitter" />}
              disabled={loading}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Item
            name="termsAndPrivacy"
            rules={[validateCheckboxTerms]}
            valuePropName="checked"
          >
            <Checkbox className={styles.checkBox} onChange={handleChangeState}>
              <span className={styles.terms}>
                {translate("termsAndPrivacy2")}
              </span>
              <a
                href={terms}
                className={styles.termsAndPrivacy}
                target="_blank"
                rel="noreferrer"
              >
                <span>{translate("term")}</span>
              </a>
              <span className={styles.terms2}>{translate("and")}</span>
              <a
                href={privacy}
                className={styles.termsAndPrivacy}
                target="_blank"
                rel="noreferrer"
              >
                <span>{translate("privacy")}</span>
              </a>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row justify="center" className="mt16">
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={loading || !checked}
            onClick={() => setLoadingButton(true)}
            loading={loadingButton}
          >
            {translate("continue")}
          </Button>
        </Form.Item>
      </Row>
    </>
  )
}

export default StepPhotoRegistration
