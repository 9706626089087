import "moment/locale/pt-br"

import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import { maskPhone } from "@utils/strings"
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Progress,
  Radio,
  Row,
  Select,
  Tooltip,
} from "antd"
import locale from "antd/es/date-picker/locale/pt_BR"
import cx from "classnames"
import _map from "lodash/map"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import InputMask from "react-input-mask"
import { useIntl } from "react-intl"

import styles from "./style.module.less"

const { Option } = Select

const StepUserRegistration = (props) => {
  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState(null)
  const [username, setUsername] = useState("")
  const [rg, setRg] = useState("")
  const rgInput = useRef()

  const intl = useIntl()

  const {
    retrieveAllCities,
    handleSelectDocumentType,
    progressCount,
    documentType,
    progress,
    states,
    cities,
    areaCode,
    form,
    handleBlurPhoneNumber,
    validate,
    verifiedPhone,
    setVerifiedPhone,
    loadingButton,
    setLoadingButton,
  } = props

  const progressBar = {
    33: {
      color: "exception",
      text: intl.formatMessage({ id: "bad" }),
    },
    66: {
      color: "active",
      text: intl.formatMessage({ id: "medium" }),
    },
    100: {
      color: "success",
      text: intl.formatMessage({ id: "excelent" }),
    },
  }

  useEffect(() => {
    if (form.getFieldValue("phone")) {
      setPhone(form.getFieldValue("phone"))
      setVerifiedPhone(true)
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue({ phone: maskPhone(phone) })
  }, [phone])

  const handleChangePhoneNumber = (e) => {
    let value = e.target.value
    setPhone(value)
    setVerifiedPhone(false)
  }

  const handleChangeState = (value) => {
    retrieveAllCities(value)
  }

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue("newPassword") === value) {
        return Promise.resolve()
      }

      return Promise.reject(intl.formatMessage({ id: "passwordReject" }))
    },
  })

  const validateFullName = () => ({
    validator(rule, value) {
      if (value) {
        const regexNameOnlyCharacters = new RegExp(
          "[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        )

        if (!regexNameOnlyCharacters.test(value)) {
          return Promise.reject(
            intl.formatMessage({ id: "errorOnlyCharacters" })
          )
        }
      }

      return Promise.resolve()
    },
  })

  const validateDate = () => ({
    validator(rule, value) {
      const dateString = moment(value, "DD/MM/YYYY")
      const validDate = dateString.isValid()
      if (value) {
        const birthYear = dateString.year()
        if (!validDate) {
          return Promise.reject(intl.formatMessage({ id: "invalidDate" }))
        }

        if (birthYear < 1900) {
          return Promise.reject(intl.formatMessage({ id: "errorYear" }))
        }

        if (birthYear > moment().get("year")) {
          return Promise.reject(intl.formatMessage({ id: "errorCurrentYear" }))
        }

        if (moment().diff(value, "years") < 18) {
          return Promise.reject(intl.formatMessage({ id: "ageValidation" }))
        }
      }
      return Promise.resolve()
    },
  })

  const validateCPF = () => ({
    async validator(rule, value) {
      if (value) {
        const regexCPF = new RegExp(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/)

        if (!regexCPF.test(value)) {
          return Promise.reject(intl.formatMessage({ id: "errorCPF" }))
        }

        const unique = await validate({
          documentNumber: value,
          documentType: "cpf",
        })

        if (!unique) {
          return Promise.reject(
            intl.formatMessage({ id: "validateDocumentNumberUnique" })
          )
        }
      }
      return Promise.resolve()
    },
  })

  const validateRG = () => ({
    async validator(rule, value) {
      if (value) {
        if (value.length < 9) {
          return Promise.reject(intl.formatMessage({ id: "errorRG" }))
        }

        const unique = await validate({
          documentNumber: value,
          documentType: "rg",
        })

        if (!unique) {
          return Promise.reject(
            intl.formatMessage({ id: "validateDocumentNumberUnique" })
          )
        }
      }
      return Promise.resolve()
    },
  })
  const validateNickname = () => ({
    async validator(rule, value) {
      if (value) {
        const regexNickname = new RegExp(/^[a-zA-Zç\s]+$/)

        if (!regexNickname.test(value)) {
          return Promise.reject(intl.formatMessage({ id: "errorNickname" }))
        }
      }
      return Promise.resolve()
    },
  })
  const validateUsername = () => ({
    async validator(rule, value) {
      if (value) {
        const regexUsername = new RegExp(/^[a-z]+$/)

        if (!regexUsername.test(value)) {
          return Promise.reject(intl.formatMessage({ id: "errorUsername" }))
        }

        if (value.length < 3) {
          return Promise.reject(intl.formatMessage({ id: "minCharacter_3" }))
        }

        const unique = await validate({
          username: value,
        })

        if (!unique) {
          return Promise.reject(
            intl.formatMessage({ id: "validateUsernameUnique" })
          )
        }
      }
      return Promise.resolve()
    },
  })

  const validateCNH = () => ({
    async validator(rule, value) {
      if (value) {
        const regexCPF = new RegExp(/^\d{11}$/)

        if (!regexCPF.test(value)) {
          return Promise.reject(intl.formatMessage({ id: "errorCNH" }))
        }

        const unique = await validate({
          documentNumber: value,
          documentType: "cnh",
        })

        if (!unique) {
          return Promise.reject(
            intl.formatMessage({ id: "validateDocumentNumberUnique" })
          )
        }
      }
      return Promise.resolve()
    },
  })

  const validatePassport = () => ({
    async validator(rule, value) {
      const passport = /^[A-Za-z]{2}[0-9]{6}/
      if (value) {
        if (!passport.test(value)) {
          return Promise.reject(intl.formatMessage({ id: "errorPassaport" }))
        }

        const unique = await validate({
          documentNumber: value,
          documentType: "passport",
        })

        if (!unique) {
          return Promise.reject(
            intl.formatMessage({ id: "validateDocumentNumberUnique" })
          )
        }
      }
      return Promise.resolve()
    },
  })

  const validatePhone = () => ({
    async validator(rule, value) {
      if (value) {
        value = maskPhone(value)

        const regexPhoneNumber = new RegExp(
          /^(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/
        )
        // const regexPhoneNumber = new RegExp(/^(\(\d{2}\)) (\d{4,5})-(\d{4})$/) // para wpp c/ fixo

        if (!regexPhoneNumber.test(value)) {
          setPhoneError(intl.formatMessage({ id: "errorPhoneNumber" }))
          return Promise.reject(intl.formatMessage({ id: "errorPhoneNumber" }))
        }

        const unique = await validate({ phoneNumber: value.replace(/\D/g, "") })

        if (!unique) {
          setPhoneError(intl.formatMessage({ id: "validatePhoneUnique" }))
          return Promise.reject(
            intl.formatMessage({ id: "validatePhoneUnique" })
          )
        }

        if (!verifiedPhone) {
          handleBlurPhoneNumber()
        }

        setPhoneError(null)
        return Promise.resolve()
      }

      setPhoneError("Campo obrigatório")
      return Promise.reject()
    },
  })

  const handleRGMask = () => {
    if (
      rgInput.current !== undefined &&
      rgInput.current !== null &&
      rg.length === 11 &&
      rgInput.current.getCursorPosition() === 11
    ) {
      rgInput.current.setCursorPosition(99)
    }

    if (rg.length < 11 || (rg.length === 11 && rg.slice(-1) === "-")) {
      return "99.999.9999"
    }

    return "99.999.999-9"
  }

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col span={24} xs={24} md={24}>
          <Form.Item
            label={translate("username")}
            name="username"
            rules={[{ required: true }, validateUsername]}
            normalize={(e) => e.toLowerCase()}
            validateTrigger="onBlur"
          >
            <InputMask
              mask="aaaaaaaaaaaaaaaaaaaaaaaaa"
              maskChar={null}
              className="input-general"
              autoComplete="off"
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Col>
        <Col span={12} xs={24} md={12}>
          <div className={styles.labelNickname}>
            <label className={cx("scorpion-main", styles.customLabel)}>
              Whatsapp
            </label>
          </div>

          <div className="flex flex-row">
            <div className="w-33">
              <Form.Item name="codeNumber" rules={[{ required: true }]}>
                <Select
                  showSearch
                  className="selectGeneral"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    areaCode && (
                      <div>{intl.formatMessage({ id: "noData" })}</div>
                    )
                  }
                  loading={!areaCode}
                >
                  {_map(areaCode, (item) => (
                    <Option
                      key={item.id}
                      value={item.code}
                    >{`+${item.code}`}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="ml8 w-100">
              <Form.Item
                name="phone"
                rules={[{ type: "string", required: true }, validatePhone]}
                validateStatus={phoneError && "error"}
                help={phoneError}
                validateTrigger="onBlur"
              >
                <Input
                  value={phone}
                  onChange={handleChangePhoneNumber}
                  /*  placeholder={intl.formatMessage({ id: "typePhone" })} */
                  className="input-general-phone"
                  maxLength={15}
                />
              </Form.Item>
            </div>
          </div>
        </Col>

        <Col span={12} xs={24} md={12}>
          <Form.Item
            label={translate("dateOfBirth")}
            name="dateOfBirth"
            rules={[{ required: true }, validateDate]}
            validateTrigger="onBlur"
          >
            <InputMask
              mask="99/99/9999"
              maskChar={null}
              className="input-general"
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col span={12} xs={24} md={12}>
          <Form.Item
            label={translate("fullName")}
            name="fullName"
            validateTrigger="onBlur"
            rules={[
              { required: true },
              {
                pattern: "[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$",
                message: intl.formatMessage({ id: "errorOnlyCharacters" }),
              },
              validateFullName,
            ]}
          >
            <Input
              className="input-general"
              /*  placeholder={intl.formatMessage({ id: "typeNameFull" })} */
            />
          </Form.Item>
        </Col>

        <Col span={12} xs={24} md={12}>
          <div className={styles.labelNickname}>
            <label className={cx("scorpion-main", styles.customLabel)}>
              {translate("nickname")}
            </label>

            {/* <label className={cx("i scorpion-main", styles.customLabelTwo)}>
              {translate("optional")}
            </label> */}

            <Tooltip
              color="#ffffff"
              title={intl.formatMessage({
                id: "O apelido aparecerá no lugar do seu nome.",
              })}
            >
              <Icon name="TooltipInfo" />
            </Tooltip>
          </div>

          <Form.Item
            tooltip
            name="nickname"
            rules={[{ required: true, validateNickname }]}
            validateTrigger="onBlur"
          >
            <Input className="input-general" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col span={12} xs={24} md={12}>
          <Form.Item
            label={translate("identityDocument")}
            name="identityDocument"
            rules={[{ type: "string", required: true }]}
          >
            <Select
              onChange={(value) => {
                handleSelectDocumentType(value)
                form.resetFields(["documentNumber"])
              }}
              /*    placeholder={intl.formatMessage({ id: "selectDocument" })} */
            >
              <Option value="cpf">CPF</Option>

              <Option value="rg">RG</Option>

              <Option value="cnh">CNH</Option>

              <Option value="passport">{translate("passport")}</Option>
            </Select>
          </Form.Item>
        </Col>

        {documentType === "cpf" && (
          <Col span={12} xs={24} md={12}>
            <Form.Item
              label={translate("documentNumber")}
              name="documentNumber"
              rules={[{ type: "string", required: true }, validateCPF]}
              validateTrigger="onBlur"
            >
              <InputMask
                mask="999.999.999-99"
                maskChar={null}
                className="input-general"
                /*  placeholder={intl.formatMessage({ id: "typeDocument" })} */
              >
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </Form.Item>
          </Col>
        )}

        {documentType === "rg" && (
          <Col span={12} xs={24} md={12}>
            <Form.Item
              label={translate("documentNumber")}
              name="documentNumber"
              rules={[{ type: "string", required: true }, validateRG]}
              validateTrigger="onBlur"
            >
              <InputMask
                ref={rgInput}
                mask={handleRGMask()}
                maskChar={null}
                alwaysShowMask={true}
                className="input-general"
                /*  placeholder={intl.formatMessage({ id: "typeDocument" })} */
                onChange={(e) => {
                  setRg(e.target.value)
                }}
              >
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </Form.Item>
          </Col>
        )}

        {documentType === "cnh" && (
          <Col span={12} xs={24} md={12}>
            <Form.Item
              label={translate("documentNumber")}
              name="documentNumber"
              rules={[{ type: "string", required: true }, validateCNH]}
              validateTrigger="onBlur"
            >
              <InputMask
                mask="99999999999"
                maskChar={null}
                className="input-general"
                /* placeholder={intl.formatMessage({ id: "typeDocument" })} */
              >
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </Form.Item>
          </Col>
        )}

        {documentType === "passport" && (
          <Col span={12} xs={24} md={12}>
            <Form.Item
              label={translate("documentNumber")}
              name="documentNumber"
              rules={[{ type: "string", required: true }, validatePassport]}
              validateTrigger="onBlur"
            >
              <InputMask
                mask="aa999999"
                maskChar={null}
                className="input-general"
                /*  placeholder={intl.formatMessage({ id: "typeDocument" })} */
              >
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </Form.Item>
          </Col>
        )}
      </Row>

      {documentType === "others" && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col span={12} xs={24} md={12}>
            <Form.Item
              label={translate("whatDocument")}
              name="whatDocument"
              rules={[{ type: "string", required: true }]}
            >
              <Input
                className="input-general"
                /*  placeholder={intl.formatMessage({ id: "typeNameDocument" })} */
              />
            </Form.Item>
          </Col>

          <Col span={12} xs={24} md={12}>
            <Form.Item
              label={translate("documentNumber")}
              name="whatDocumentNumber"
              rules={[{ type: "string", required: true }]}
            >
              <Input
                className="input-general"
                /*   placeholder={intl.formatMessage({ id: "typeDocument" })} */
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      {documentType !== "others" && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col span={12} xs={24} md={12}>
            <Form.Item
              label={translate("country")}
              name="countryBrazil"
              rules={[{ type: "string", required: true }]}
            >
              <Input
                className="input-general"
                value={form.setFieldsValue({ countryBrazil: "Brasil" })}
                disabled
              />
            </Form.Item>
          </Col>

          <Col span={12} xs={24} md={12}>
            <Form.Item
              label={translate("iAm")}
              name="creatorType"
              rules={[{ type: "number", required: true }]}
            >
              <Select
                className="select-document"
                allowClear={false}
                /*  placeholder={intl.formatMessage({ id: "selectCreatorType" })} */
              >
                <Option value={1}>{translate("model")}</Option>
                <Option value={2}>{translate("photographer")}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}

      {documentType !== "others" ? (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          {/* INPUT nome de usuário - proibir letras maiúsculas, números, pontuação,
          espaços e caracteres especiais */}
          <Col span={12} xs={24} md={12}>
            <Form.Item
              label={translate("state")}
              name="stateBrazil"
              rules={[{ type: "string", required: true }]}
            >
              <Select
                className="select-document"
                showSearch
                onChange={handleChangeState}
                /*  placeholder={intl.formatMessage({ id: "selectStateBrazil" })} */
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {_map(states, (item) => (
                  <Option key={item.id} value={item.sigla}>
                    {item.sigla}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} xs={24} md={12}>
            <Form.Item
              label={translate("city")}
              name="cityBrazil"
              rules={[{ type: "string", required: true }]}
            >
              <Select
                className="select-document"
                showSearch
                disabled={!form.getFieldValue("stateBrazil")}
                /*  placeholder={intl.formatMessage({ id: "selectCityBrazil" })} */
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {_map(cities, (item) => (
                  <Option key={item.id} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col span={12} xs={24} md={12}>
            <Form.Item
              label={translate("state")}
              name="state"
              rules={[{ type: "string", required: true }]}
            >
              <Input
                className="input-general"
                /* placeholder={intl.formatMessage({ id: "placeholderState" })} */
              />
            </Form.Item>
          </Col>

          <Col span={12} xs={24} md={12}>
            <Form.Item
              label={translate("city")}
              name="city"
              rules={[{ type: "string", required: true }]}
            >
              <Input
                className="input-general"
                /*  placeholder={intl.formatMessage({ id: "placeholderCity" })} */
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col span={12} xs={24} md={12}>
          <Form.Item
            label={translate("password")}
            name="newPassword"
            validateStatus={"error"}
            validateFirst
            rules={[
              {
                type: "string",
                required: true,
                min: 6,
              },
              {
                pattern:
                  /^(?=.*[0-9])(?=.*[a-zA-Z])([A-Za-z\d$@$!%*#=+()^?&\-_'"`~ç{}[\]\\/,.]+)$/,
                message: intl.formatMessage({ id: "passwordError" }),
              },
            ]}
            style={{ marginBottom: "0px" }}
          >
            <Input.Password
              /*  placeholder={intl.formatMessage({ id: "placeholderPassword" })} */
              onChange={(event) => progressCount(event.target.value)}
              iconRender={(visible) =>
                visible ? <Icon name="EyeOpen" /> : <Icon name="EyeBlocked" />
              }
            />
          </Form.Item>

          <div className="flex flex-row">
            <Progress
              className="mb16"
              showInfo={false}
              percent={progress}
              status={progressBar[progress].color}
            />

            <span className="dusty-gray ml12">
              {progressBar[progress].text}
            </span>
          </div>
        </Col>

        <Col span={12} xs={24} md={12}>
          <Form.Item
            label={translate("repeatPassword")}
            name="repeatPassword"
            validateStatus={"error"}
            rules={[
              { type: "string", required: true },
              validateConfirmPassword,
            ]}
          >
            <Input.Password
              iconRender={(visible) =>
                visible ? <Icon name="EyeOpen" /> : <Icon name="EyeBlocked" />
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="mt8" style={{ display: "none" }}>
        <Col>
          <Form.Item name="visibleWhatsapp" valuePropName="checked">
            <Checkbox className={styles.checkBox}>
              <span>{translate("phoneProfile")}</span>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      {documentType === "others" && (
        <Row
          justify="center"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
          className="mt24"
        >
          <Col span={12} xs={24} md={24}>
            <Form.Item name="type" rules={[{ type: "number", required: true }]}>
              <Radio.Group style={{ display: "flex" }}>
                <Radio className="radio-type-creator" value={1}>
                  {translate("modelRadio")}
                </Radio>

                <Radio className="radio-type-creator" value={2}>
                  {translate("photographerRadio")}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row justify="center" className="mt24">
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => setLoadingButton(true)}
            loading={loadingButton}
          >
            {translate("continue")}
          </Button>
        </Form.Item>
      </Row>
    </>
  )
}

export default StepUserRegistration
