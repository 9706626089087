import { LeftOutlined } from "@ant-design/icons"
import CustomModal from "@components/CustomModal"
import { CustomModalErrors } from "@components/CustomModalErrors"
import { SuccessModalStylized } from "@components/SuccessModalStylized"
import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import AuthStep from "@modules/MyAccount/ForgotPassword/components/AuthStep"
import StepApprovalRegistration from "@modules/Registration/CreatorRegistration/StepApproval"
import StepPhoto from "@modules/Registration/CreatorRegistration/StepPhoto"
// import { Icon } from "@components/Icons"
// import IconRadius from "@components/iconRadius"
import StepUserRegistration from "@modules/Registration/CreatorRegistration/StepUser"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import styles from "@pages/cadastro-criador/style.module.less"
import translate from "@src/i18n"
import { getOnlyDigits } from "@utils/strings"
import { Button, Divider, Form, Row, Steps, Tooltip } from "antd"
import cx from "classnames"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { Link, navigate } from "gatsby"
import _get from "lodash/get"
import _map from "lodash/map"
import _uniqBy from "lodash/uniqBy"
import { observer } from "mobx-react"
import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useMedia } from "react-use-media"

import { resizeImage } from "../../../utils/file"

import SvgCloseModal from "../../../components/Icons/components/CloseModal"

dayjs.extend(customParseFormat)

const { Step } = Steps

const Content = () => {
  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(0)
  const [statusUser, setStatusUser] = useState("process")
  const [statusPhoto, setStatusPhoto] = useState("whait")
  const [statusMail, setStatusMail] = useState("whait")
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [areaCode, setAreaCode] = useState([])
  const [countries, setCountries] = useState([])
  const [photo, setPhoto] = useState()
  const [cover, setCover] = useState()
  const [photoEmpty, setPhotoEmpty] = useState(false)
  const [coverEmpty, setCoverEmpty] = useState(false)
  const [bodyStepOne, setBodyStepOne] = useState()
  const [documentType, setDocumentType] = useState("cpf")
  const [progress, setProgress] = useState(33)
  const [checkCode, setCheckCode] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [visibleValidatePhoneNumber, setVisibleValidatePhoneNumber] =
    useState(false)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [loadingVerifyPhone, setLoadingVerifyPhone] = useState(false)
  const [errors, setErrors] = useState([])
  const [verifiedPhone, setVerifiedPhone] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [filePhoto, setFilePhoto] = useState()
  const [fileCover, setFileCover] = useState()
  const [loadingButton, setLoadingButton] = useState(false)

  const [form] = Form.useForm()

  const intl = useIntl()

  const tablet = useMedia({ minWidth: 768 })

  const { registrationAffiliated, authSite, upload } = useStores()

  const {
    getAllStates,
    getAllCities,
    getAllCountriesAreaCode,
    registerCreator,
    validate,
  } = registrationAffiliated

  const { sendCode } = authSite

  const { getSignedUrlS3, uploadToS3 } = upload

  const VALIDATE_MESSAGES = {
    required: intl.formatMessage({ id: "requiredInput" }),
    string: {
      min: intl.formatMessage({ id: "messageErrorPassword" }),
    },
  }

  useEffect(() => {
    setIsClient(true)
    retrieveAllStates()
    retrieveAllCountriesAreaCode()
  }, [])

  useEffect(() => {
    form.validateFields(["codeNumberWhatsapp"])
  }, [checkCode])

  const handleStepSelected = (selected) => {
    setCurrent(selected)

    if (selected === 0) {
      setStatusUser("process")
      setStatusPhoto("whait")
      setStatusMail("whait")
    } else if (selected === 1) {
      setStatusUser("finish")
      setStatusPhoto("process")
      setStatusMail("whait")
    } else if (selected === 2) {
      setStatusUser("finish")
      setStatusPhoto("finish")
      setStatusMail("finish")
    }
  }

  const handleSelectDocumentType = (value) => {
    setDocumentType(value)
  }

  const progressCount = (value) => {
    const passwordLength = value.length

    if (passwordLength <= 3) {
      setProgress(33)
      return
    }

    if (passwordLength <= 6) {
      setProgress(66)
      return
    }

    setProgress(100)
    return
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handlePreviewPhoto = async (image) => {
    if (image) {
      setFilePhoto(image.file.originFileObj)
      const photoBase64 = await getBase64(image.file.originFileObj)
      const photoBody = { data: photoBase64, name: image.file.name }
      setPhoto(photoBody)
    }
  }

  const handlePreviewCover = async (image) => {
    if (image) {
      setFileCover(image.file.originFileObj)
      const coverBase64 = await getBase64(image.file.originFileObj)
      const coverBody = { data: coverBase64, name: image.file.name }
      setCover(coverBody)
    }
  }

  const handleFieldsChange = (changedFields) => {
    const fieldName = _get(changedFields, "[0].name[0]", "")
    const fieldValue = _get(changedFields, "[0].value", "")

    if (fieldName === "whatsappNumber") {
      const newCheckCode = fieldValue !== "" && fieldValue !== "("
      if (checkCode !== newCheckCode) {
        setCheckCode(newCheckCode)
      }
    }
  }

  const handleFinishFormOne = async (props, value) => {
    if (!verifiedPhone) {
      setErrors(errorsAdapter(translate("validatePhoneError")))
      setLoadingButton(false)
      return
    }

    if (value === 0) {
      if (props.identityDocument === "others") {
        const actualDataOne = {
          type: props.type,
          name: props.fullName,
          username: props.username,
          state: props.state,
          city: props.city,
          documentType: props.whatDocument,
          documentNumber: props.whatDocumentNumber,
          password: props.newPassword,
          nickname: props.nickname,
          country: props.country,
          phoneNumber:
            props.codeNumber && props.phone
              ? (props.codeNumber + props.phone).replace(/[^0-9]+/g, "")
              : "",
          dateBirth: dayjs(props.dateOfBirth, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          visibleWhatsapp: props.visibleWhatsapp,
        }

        setBodyStepOne(actualDataOne)
        handleStepSelected(1)
        setLoadingButton(false)
      } else {
        const actualDataTwo = {
          type: props.creatorType,
          name: props.fullName,
          username: props.username,
          state: props.stateBrazil,
          city: props.cityBrazil,
          documentType: props.identityDocument,
          documentNumber: props.documentNumber,
          password: props.newPassword,
          nickname: props.nickname,
          country: props.countryBrazil,
          phoneNumber:
            props.codeNumber && props.phone
              ? (props.codeNumber + props.phone).replace(/[^0-9]+/g, "")
              : "",
          dateBirth: dayjs(props.dateOfBirth, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          visibleWhatsapp: props.visibleWhatsapp,
        }

        setBodyStepOne(actualDataTwo)
        handleStepSelected(1)
        setLoadingButton(false)
      }
    } else {
      setPhotoEmpty(!photo)
      setCoverEmpty(!cover)

      if (!photo || !cover) {
        setLoadingButton(false)
        return
      }

      setLoading(true)

      const paramsPhoto = {
        mediaPath: "images",
        fileName: filePhoto.name,
        s3Path: "affiliated",
      }

      const signedUrlPhotoRes = await getSignedUrlS3(paramsPhoto)

      if (!signedUrlPhotoRes) {
        setLoadingButton(false)
        setLoading(false)
        const adaptedErrors = errorsAdapter("Error get signed url")
        setErrors(adaptedErrors)
        return
      }

      const { url: urlPhoto, filePath: filePathPhoto } = signedUrlPhotoRes

      const resizedPhoto = await resizeImage(filePhoto, 140)

      const uploadToS3PhotoRes = await uploadToS3(urlPhoto, resizedPhoto)

      if (!uploadToS3PhotoRes) {
        setLoadingButton(false)
        setLoading(false)
        const adaptedErrors = errorsAdapter("Error photo upload to S3")
        setErrors(adaptedErrors)
        return
      }

      const paramsCover = {
        mediaPath: "images",
        fileName: fileCover.name,
        s3Path: "affiliated",
      }

      const signedUrlCoverRes = await getSignedUrlS3(paramsCover)

      if (!signedUrlCoverRes) {
        setLoadingButton(false)
        setLoading(false)
        const adaptedErrors = errorsAdapter("Error get signed url")
        setErrors(adaptedErrors)
        return
      }

      const { url: urlCover, filePath: filePathCover } = signedUrlCoverRes

      const resizedCover = await resizeImage(fileCover, 1200)

      const uploadToS3CoverRes = await uploadToS3(urlCover, resizedCover)

      if (!uploadToS3CoverRes) {
        setLoadingButton(false)
        setLoading(false)
        const adaptedErrors = errorsAdapter("Error photo upload to S3")
        setErrors(adaptedErrors)
        return
      }

      const actualDataThree = {
        urlProfile: filePathPhoto,
        urlCoverPage: filePathCover,
        describe: props.describe,
        whatsapp:
          props.codeNumberWhatsapp && props.whatsappNumber
            ? (props.codeNumberWhatsapp + props.whatsappNumber).replace(
                /[^0-9]+/g,
                ""
              )
            : "",
        email: props.publicEmail,
        publicEmail: props.publicEmail,
        instagram: props.instagram,
        twitter: props.twitter,
      }

      handleRegistrationCreator(actualDataThree)
    }
  }

  const handleRegistrationCreator = async (data) => {
    const body = { ...bodyStepOne, ...data, status: 2 }
    const response = await registerCreator(body)

    if (response) {
      const errorMessage = _get(response, "message", "")
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      setLoading(false)
      setLoadingButton(false)
      return
    }

    setLoadingButton(false)
    setLoading(false)
    handleStepSelected(2)
  }

  const handleBlurPhoneNumber = () => {
    const codeNumber = form.getFieldValue("codeNumber")
    const phone = form.getFieldValue("phone")

    const regexPhoneNumber = new RegExp(/^(\(\d{2}\)) (\d{5})-(\d{4})$/)

    if (phone && regexPhoneNumber.test(phone)) {
      const phoneNumber = `${codeNumber} ${phone}`

      sendCode({ phone: getOnlyDigits(phoneNumber) })

      setPhoneNumber(phoneNumber)
      setVisibleValidatePhoneNumber(true)
    }
  }

  const handleResetStep = () => {
    setVisibleValidatePhoneNumber(false)
    form.getFieldInstance("phone").input.focus()
  }

  const handleSuccessOnlyValidate = () => {
    setVisibleValidatePhoneNumber(false)
    setVerifiedPhone(true)
    setSuccessMessage(translate("validatePhoneSuccess"))
  }

  const handleErrorOnlyValidate = () => {
    setVerifiedPhone(false)
  }

  const retrieveAllStates = useCallback(async () => {
    const response = await getAllStates()
    const arrayData = []
    _map(response, (item) => {
      const data = { id: item.id, sigla: item.sigla }
      arrayData.push(data)
    })
    arrayData.sort((a, b) => {
      return a.sigla.localeCompare(b.sigla)
    })
    setStates(arrayData)
  }, [])

  const retrieveAllCities = useCallback(async (props) => {
    const response = await getAllCities(props)
    let arrayCities = []

    _map(response, (item) => {
      const data = { id: item.id, name: item.nome }
      arrayCities.push(data)
    })

    setCities(arrayCities)
    arrayCities = []
  }, [])

  const retrieveAllCountriesAreaCode = async () => {
    const response = await getAllCountriesAreaCode()
    const arrayDataAreaCode = []
    const arrayDataCountries = []

    _map(response, (item) => {
      const dataAreaCode = { id: item.id, code: item.areaCode }
      const dataCountries = { id: item.id, name: item.nameCountry }

      arrayDataAreaCode.push(dataAreaCode)
      arrayDataCountries.push(dataCountries)
    })

    const uniqByAreaCode = _uniqBy(arrayDataAreaCode, "code")
    uniqByAreaCode.sort((a, b) => {
      return a.code - b.code
    })
    setAreaCode(uniqByAreaCode)
    setCountries(arrayDataCountries)
  }

  if (!isClient) return null

  const renderItemMenu = () => {
    switch (current) {
      case 0:
        return (
          <div className={cx("tc mt48 mb32", styles.notCenter)}>
            <div className={styles.container_title}>
              <Link to="/">
                <button
                  title="Close (Esc)"
                  type="button"
                  className={styles.buttonClose}
                >
                  <SvgCloseModal />
                </button>
              </Link>
              <div>
                <div>
                  <h3>{translate("titleUser")}</h3>
                </div>
                <p className={styles.textContainer}>
                  <Link to="/login" className={styles.loginAnAccount}>
                    {translate("titleUserLogin")}
                  </Link>
                  <span className="f14 normal scorpion-main">
                    {translate("titleUserDescriptionSubCreate")}
                  </span>
                  <Link
                    to="/cadastro-assinante"
                    className={styles.creatAnAccount}
                  >
                    {translate("titleUserDescriptionSub")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        )
      case 1:
        return (
          <div className={cx("tc mt48 mb32", styles.notCenter)}>
            <span className="f20 fw5 mine-shaft">
              {translate("titlePhoto")}
            </span>
            <p>
              <span className="f14 normal scorpion-main">
                {translate("titlePhotoSub")}
              </span>
            </p>
          </div>
        )
      case 2:
        return (
          <div className={cx("tc mt48 mb32", styles.notCenter)}>
            <span className="f20 fw5 mine-shaft">
              {translate("titleApproval")}
            </span>
            <p>
              <span className="f14 normal scorpion-main">
                {translate("titleApprovalSub")}
              </span>
            </p>
          </div>
        )
      default:
        return
    }
  }

  const renderItemStep = (current) => {
    switch (current) {
      case 0:
        return (
          <TranslateWrapper>
            <StepUserRegistration
              handleStepSelected={handleStepSelected}
              retrieveAllCities={retrieveAllCities}
              handleSelectDocumentType={handleSelectDocumentType}
              progressCount={progressCount}
              documentType={documentType}
              progress={progress}
              states={states}
              cities={cities}
              countries={countries}
              areaCode={areaCode}
              form={form}
              handleBlurPhoneNumber={handleBlurPhoneNumber}
              validate={validate}
              verifiedPhone={verifiedPhone}
              setVerifiedPhone={setVerifiedPhone}
              loadingButton={loadingButton}
              setLoadingButton={setLoadingButton}
            />
          </TranslateWrapper>
        )
      case 1:
        return (
          <TranslateWrapper>
            <StepPhoto
              handleStepSelected={handleStepSelected}
              handlePreviewPhoto={handlePreviewPhoto}
              handlePreviewCover={handlePreviewCover}
              cover={cover}
              coverEmpty={coverEmpty}
              areaCode={areaCode}
              photo={photo}
              photoEmpty={photoEmpty}
              loading={loading}
              form={form}
              checkCode={checkCode}
              validate={validate}
              loadingButton={loadingButton}
              setLoadingButton={setLoadingButton}
            />
          </TranslateWrapper>
        )
      case 2:
        return (
          <TranslateWrapper>
            {" "}
            <StepApprovalRegistration />{" "}
          </TranslateWrapper>
        )
      default:
        return
    }
  }

  return (
    <TranslateWrapper>
      <div
        className={cx("creator-registration mc center", styles.paddingCreator)}
      >
        {renderItemMenu(current)}
        <div className="flex justify-center align-center">
          <Steps current={current}>
            <Step
              className={cx("", styles.iconCheck)}
              // icon={
              //   statusUser === "whait" ? (
              //     <Icon name="UserWait" />
              //   ) : statusUser === "process" ? (
              //     <IconRadius icon="UserFinish" pr={60} />
              //   ) : (
              //     <Icon name="UserFinish" />
              //   )
              // }
            />
            <Step
            // icon={
            //   statusPhoto === "whait" ? (
            //     <Icon name="PhotoWait" />
            //   ) : statusPhoto === "process" ? (
            //     <IconRadius icon="PhotoFinish" pr={0} action="photo" />
            //   ) : (
            //     <Icon name="PhotoFinish" />
            //   )
            // }
            />
            {/*  <Step
             icon={
              statusMail !== "whait" ? (
                <IconRadius
                  icon="MailFinish"
                   pr={75}
                   status={statusMail}
                  action="mail"
                />
              ) : (
                 <Icon name="MailWait" />
               )
             }
            /> */}
          </Steps>
        </div>
        <div
          className={
            current === 0
              ? "step-general flex justify-center mt32 pb72"
              : current === 1 &&
                cx(
                  "step-general flex flex-column items-center justify-center mt32 pb72",
                  styles.notAlign
                )
          }
        >
          <Form
            className={styles.formWidth}
            form={form}
            layout="vertical"
            validateMessages={VALIDATE_MESSAGES}
            onFieldsChange={handleFieldsChange}
            onFinish={(props) => handleFinishFormOne(props, current)}
            onFinishFailed={() => {
              setLoadingButton(false)
            }}
            initialValues={{
              codeNumber: "+55",
              identityDocument: "cpf",
              visibleWhatsapp: false,
            }}
          >
            {renderItemStep(current)}
          </Form>
        </div>
      </div>

      {visibleValidatePhoneNumber ? (
        <CustomModal
          visible={visibleValidatePhoneNumber}
          className="validate-phone"
          centered={tablet}
          handleClose={() => setVisibleValidatePhoneNumber(false)}
        >
          <Row justify="center" className="pt48 pb48 forgotPasswordContext">
            <AuthStep
              handleResetStep={handleResetStep}
              phoneNumber={phoneNumber}
              loading={loadingVerifyPhone}
              setLoading={setLoadingVerifyPhone}
              setErrors={setErrors}
              onlyValidate
              handleSuccessOnlyValidate={handleSuccessOnlyValidate}
              handleErrorOnlyValidate={handleErrorOnlyValidate}
            />
          </Row>
        </CustomModal>
      ) : null}

      {errors.length ? (
        <CustomModalErrors errors={errors} setErrors={setErrors} />
      ) : null}

      {successMessage ? (
        <SuccessModalStylized
          visible={!!successMessage}
          description={successMessage}
          handleClose={() => setSuccessMessage(null)}
          buttonText={intl.formatMessage({ id: "continue" })}
          modalOption="tokenConfirmed"
        />
      ) : null}
    </TranslateWrapper>
  )
}

export default observer(Content)
