import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import cx from "classnames"
import React from "react"

import styles from "./style.module.less"

const StepApprovalRegistration = () => {
  return (
    <div
      className={cx(
        "step-general flex flex-column justify-center items-center mt32",
        styles.minBottom
      )}
    >
      <div className="flex flex-row mb32">
        <Icon name="MyDocuments" className={styles.iconMobile} />
        <span className={cx("ml24 scorpion-main", styles.textContainer)}>
          {translate("creatorDocuments")}
        </span>
      </div>
      <div className="flex flex-row mb32">
        <Icon name="MySelfie" className={styles.iconMobile} />
        <span className={cx("ml24 scorpion-main", styles.textContainer)}>
          {translate("creatorSelfie")}
        </span>
      </div>
      <div className="flex flex-row mb32">
        <Icon name="AnonimattaMail" className={styles.iconMobile} />
        <p className={cx("ml24", styles.textContainer)}>
          <span className="scorpion-main">{translate("creatorMail")}</span>
          <span className="fw6 kimberly">{translate("creatorMailTwo")}</span>
          <span className="scorpion-main">{translate("creatorMailThree")}</span>
        </p>
      </div>
      <span className={cx("f20 b tc scorpion-main", styles.notCenter)}>
        {translate("creatorActiveAccount")}
      </span>
      <div className={cx("tc mt4", styles.notCenter)}>
        <span className="f14 normal scorpion-main">
          {translate("creatorValidated")}
        </span>
      </div>
    </div>
  )
}

export default StepApprovalRegistration
